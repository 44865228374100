var adhome = adhome || {};
adhome.View = adhome.View || {};
adhome.View.main = adhome.View.main || {};

adhome.View.main.slider = function () {
  this.init();
};
adhome.View.main.slider.prototype = {
  _isPlayingEnabled: true,
  init() {
    this._assignElements();
    this._assignComponents();
  },
  _assignElements() {
    this._welMainSlider = $('#_adhome_swiper');
    this._welMainSliderWrap = $('#_adhome_swiper_wrapper');
    this._welMainSliderList = this._welMainSliderWrap.find('.swiper-slide');
    this._welMainSliderCtrls = this._welMainSlider.find('.adhome_swiper_ctrls');
    this._welSliderCtrlPrev = this._welMainSliderCtrls.find('.swiper_ctrl_prev');
    this._welSliderCtrlNext = this._welMainSliderCtrls.find('.swiper_ctrl_next');
    this._welSliderCtrlAutoPlay = this._welMainSliderCtrls.find('.swiper_ctrl_autoplay');
    this._welSliderCtrlPage = this._welMainSliderCtrls.find('.swiper_ctrl_pager');
  },
  _assignComponents() {
    var slideLength = this._welMainSliderList.length > 1;

    this._oMainSwiper = new Swiper('#_adhome_swiper .swiper-container', {
      loop: slideLength,
      lazy: {
        loadPrevNext: true // 이전, 다음 이미지는 미리 로딩
      },
      slidesPerView: 'auto',
      centeredSlides: true,
      //loopedSlides: 3,
      //loopAdditionalSlides: 1, // 슬라이드 반복 시 마지막 슬라이드에서 다음 슬라이드가 보여지지 않는 현상
      //touchStartPreventDefault: false,
      // simulateTouch: false,
      // observer: true,
      // observeParents: true,
      preventClicks: true,
      preventClicksPropagation: false,
      speed: 800,
      slideToClickedSlide: true, // 해당 슬라이드 클릭시 슬라이드 위치로 이동
      allowTouchMove: false,
      //watchOverflow: true, // 슬라이드가 1개 일 때 pager, button 숨김 여부
      autoplay: {
        delay: 5000,
        disableOnInteraction: false //스와이프 후 자동 재생
      },
      navigation: slideLength
        ? {
            prevEl: this._welSliderCtrlPrev[0],
            nextEl: this._welSliderCtrlNext[0]
          }
        : false,
      pagination: slideLength
        ? {
            el: this._welSliderCtrlPage[0],
            type: 'custom',
            renderCustom: function (swiper, current, total) {
              return `
                <span class="blind">현재 슬라이드 배너 페이지</span>
                <span class="slider_page_current">${current}</span>
                <span class="slider_page_separator" aria-hidden="true">/</span>
                <span class="blind">전체 슬라이드 배너 페이지</span>
                <span class="slider_page_total">${total}</span>
              `;
            }
          }
        : false,
      a11y: {
        prevSlideMessage: '이전 슬라이드 배너 보기',
        nextSlideMessage: '다음 슬라이드 배너 보기',
        slideLabelMessage:
          '총 {{slidesLength}}장의 슬라이드 배너 중 {{index}}번째 슬라이드 배너 입니다.'
      },
      on: {
        init: (swiper) => {
          this._startSliderObserve();
          if (slideLength) {
            this._welSliderCtrlAutoPlay.on('click', (e) => this._onClickCtrlAuto(e));
            this._welMainSlider
              .on('mouseenter', (e) => this._onMouseenterSlider(e))
              .on('mouseleave', (e) => this._onMouseleaveSlider(e));
            this._welMainSliderList.on('focusin', (e) => this._onFocusinSlider(e));
            this._welMainSliderList.on('focusout', (e) => this._onFocusoutSlider(e));
          }

          if ($('.adhome_topbn').find('.adhome_topbn_titbx').length > 0) {
            this._welMainSliderList.each((index, elem) => {
              $('.adhome_topbn_titwrap').append($(elem).find('.adhome_topbn_titbx').clone());
            });
            $('.adhome_topbn_titwrap')
              .find('.adhome_topbn_titbx')
              .eq(swiper.realIndex)
              .addClass('on')
              .siblings()
              .removeClass('on');
          }
        },
        slideChangeTransitionStart: (swiper) => {
          if ($('.adhome_topbn').find('.adhome_topbn_titbx').length > 0) {
            $('.adhome_topbn_titwrap')
              .find('.adhome_topbn_titbx')
              .eq(swiper.realIndex)
              .addClass('on')
              .siblings()
              .removeClass('on');
          }
        }
      }
    });

    this._oMainSwiper.on('slideChangeTransitionEnd', () => this._enableActiveSlide());
    this._oMainSwiper.on('navigationPrev', () => this._onClickPrevSlide());
    this._oMainSwiper.on('navigationNext', () => this._onClickNextSlide());
  },
  _startSliderObserve() {
    let firstIntersecting = true;

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (firstIntersecting) {
              firstIntersecting = false;
              this._enableActiveSlide();
            }
            //console.log('inview');
            this._oMainSwiper.autoplay.start();
          } else {
            //console.log('outview');
            this._oMainSwiper.autoplay.stop();
          }
        });
      },
      {
        threshold: 0
      }
    );
    observer.observe(this._welMainSlider[0]);
  },
  _enableActiveSlide() {
    var welActiveSlide = $(this._oMainSwiper.slides[this._oMainSwiper.activeIndex]);
    welActiveSlide
      .find('a')
      .attr('aria-hidden', 'false')
      .prop('tabIndex', '0')
      .end()
      .siblings()
      .attr('aria-hidden', 'true')
      .find('a')
      .prop('tabIndex', '-1');
    // welActiveSlide.trigger('enable.heroBanner');
  },
  _onMouseenterSlider() {
    if (!this._isPlayingEnabled) {
      return;
    }
    this._oMainSwiper.autoplay.stop();
  },
  _onMouseleaveSlider() {
    if (!this._isPlayingEnabled) {
      return;
    }
    this._oMainSwiper.autoplay.start();
    this._welMainSliderWrap.attr('aria-live', 'off');
  },
  _onFocusinSlider() {
    this._oMainSwiper.autoplay.running && this._oMainSwiper.autoplay.stop();
  },
  _onFocusoutSlider() {
    this._welMainSliderWrap.attr('aria-live', 'off');
  },
  _onClickPrevSlide() {
    this._welMainSliderWrap.attr('aria-live', 'polite');
  },
  _onClickNextSlide() {
    this._welMainSliderWrap.attr('aria-live', 'polite');
  },
  _onClickCtrlAuto(e) {
    var welTarget = $(e.currentTarget);
    if (welTarget.hasClass('on')) {
      this._stopAuto();
    } else {
      this._startAuto();
    }
  },
  _startAuto() {
    this._isPlayingEnabled = true;
    this._oMainSwiper.autoplay.start();
    this._welSliderCtrlAutoPlay.addClass('on');
    this._welMainSliderWrap.attr('aria-live', 'off');
  },
  _stopAuto() {
    this._isPlayingEnabled = false;
    this._oMainSwiper.autoplay.stop();
    this._welSliderCtrlAutoPlay.removeClass('on');
    this._welMainSliderWrap.attr('aria-live', 'polite');
  }
};

adhome.View.main.parallax = function () {
  this.init();
};
adhome.View.main.parallax.prototype = {
  init() {
    this._assignElements();
    this._assignComponents();
  },
  _assignElements() {
    this._welTarget = $('._js_adhome_ani');
  },
  _assignComponents() {
    var intersectionCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(entry.target).addClass('ani_effect');
          observer.unobserve(entry.target);
        }
      });
    };

    const options = {
      root: null,
      threshold: 0.4
    };
    var observer = new IntersectionObserver(intersectionCallback, options);
    var target = document.querySelectorAll('._js_adhome_ani');
    target.forEach((elem) => {
      observer.observe(elem);
    });
  }
};

$(function () {
  if (adhome.util.hasElement('#_adhome_swiper')) {
    var oAdhomeMainSlider = new adhome.View.main.slider();
  }
  if (adhome.util.hasElement('._js_adhome_ani')) {
    var oAdhomeMainParallax = new adhome.View.main.parallax();
  }
});
